<template>
  <div />
  <!--v-footer
    id="dashboard-core-footer"
    color="white"
    absolute
  >
    <span class="pl-2">
      Data Company Desenvolvimento de Sistemas
    </span>
  </v-footer-->
</template>

<script>
  export default {
    name: 'DashboardCoreFooter',

    data: () => ({
      links: [
        {
          href: '#',
          text: 'Data Company Desenvolvimento de Software - 2020',
        },
        {
          href: '#',
          text: 'About Us',
        },
        {
          href: '#',
          text: 'Blog',
        },
        {
          href: '#',
          text: 'Licenses',
        },
      ],
    }),
  }
</script>

<style lang="sass">
  #dashboard-core-footer
    a
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase
</style>
